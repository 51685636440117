<template>
  <public-layout>
    <div class="container mx-auto">
      <div class="flex flex-col justify-center px-4">
        <h1 class="text-2xl font-bold mb-4 text-center">
          TÉRMINOS Y CONDICIONES DE USO DEL SITIO
        </h1>
        <section class="">
          <div class="mb-8">
            Los Términos y Condiciones de uso que a continuación se presentan (los "Términos") constituyen el
            acuerdo íntegro entre <strong>GO INNOVATIONS, S.A.P.I.  de C.V. ("SAMII")</strong>,
            cuya dirección electrónica es:
            <a class="text-purple-400" @click="redirect('http://www.samiiweb.com/')">
              www.samiiweb.com
            </a>(el "Sitio") y cualquier usuario del Sitio.
            La utilización del Sitio, por parte de cualquier persona, le atribuye la calidad de usuario (el "Usuario")
            y ello implica su adhesión plena e incondicional a estos Términos.
            En consecuencia, es responsabilidad única y exclusiva del Usuario,
            leer previamente los Términos y sus modificaciones correspondientes, cada vez que accede al Sitio.
            Si en cualquier momento, el Usuario no estuviera de acuerdo total oparcialmente con los presentes Términos,
            deberá abstenerse inmediatamente de usar el Sitio.
          </div>
          <div class="mb-8">
            <strong>ACCESO, UTILIZACIÓN Y ESTANCIA EN EL SITIO.</strong>
            <div>
              El acceso y utilización del Sitio tiene carácter gratuito para los Usuarios.
              El Usuario no estará obligado a registrarse o inscribirse en el Sitio.
              Los servicios ofrecidos a través del Sitio están dirigidos a toda persona con capacidad jurídica y
              legitimación para obligarse de conformidad con los Términos y la legislación aplicable.
              El Usuario es el único responsable frente a SAMII y cualquier tercero, respecto de su conducta al acceder,
              consultar y proporcionar información al Sitio y de las consecuencias que se puedan derivar de una utilización,
              con fines o efectos ilícitos o contrarios al Objeto del Sitio, su Contenido, elaborado o no por SAMII,
              publicado o no bajo su nombre de forma oficial; así como aquellas consecuencias que se puedan derivar de la
              utilización contraria al contenido de los Términos que sea lesiva de los intereses o derechos de terceros,
              o que de cualquier forma pueda dañar, inutilizar o deteriorar el Sitio o sus servicios o impedir el normal
              disfrute de otros Usuarios.
            </div>
          </div>
          <div class="mb-8">
            <strong>OBJETO.</strong>
            <div>
              SAMII pone a disposición del Usuario el Sitio, ya sea que se trate de un sitio patrocinado, administrado
              por intermedio de un tercero o alojado por un tercero, facilitando el acceso a los Usuarios a información
              diversa proporcionada por SAMII o por personas vinculadas a dicha información de manera directa o
              indirecta (los "Contenidos"). El Usuario reconoce que el uso del Sitio no le implica ningún derecho de
              propiedad sobre el mismo, o cualquiera de sus elementos o Contenidos. SAMII se reserva el derecho a
              modificar total o parcialmente, en cualquier momento y sin aviso previo, la presentación, configuración,
              información, Contenidos y en general cualquier parte o aspecto relacionado directa o indirectamente con
              el Sitio.
            </div>
          </div>
          <div class="mb-8">
            El Usuario reconoce que las descripciones de los servicios expuestos en el Sitio se realizan de forma
            ilustrativa y tendrán el alcance y las especificaciones comunes, básicas y simples, que regularmente el
            mercado ofrece, salvo cuando dichos alcances y especificaciones se detallen en el Sitio. De igual forma,
            reconoce que la información contenida y/o publicada a través del Sitio, tiene fines meramente informativos
            sobre los servicios que proporciona SAMII.
          </div>
          <div class="mb-8">
            <strong>CONTENIDOS.</strong>
            <div>El Usuario se compromete a:</div>
            <div>
              <ul>
                <li class="pl-8 w-full flex flex-row">
                    a)
                    <div style="min-width: 25px;"/>
                    utilizar el Sitio y sus Contenidos de acuerdo a las leyes aplicables y de orden público,
                    absteniéndose de realizar acto que menoscabe, deteriore, inutilice o dañe la imagen y/o información
                    divulgada por SAMII o de alguna manera lesione derechos o intereses de terceras personas,
                    vinculadas directa o indirectamente a este;
                </li>
                <li class="pl-8 w-full flex flex-row">
                  b)
                  <div style="min-width: 25px;"/>
                  no copiar, difundir, modificar, reproducir, distribuir o utilizar de manera alguna con o sin fines de
                  lucro los contenidos y los elementos utilizados en el Sitio, a menos que se cuente con la autorización
                  expresa y por escrito de SAMII;
                </li>
                <li class="pl-8 w-full flex flex-row">
                  c)
                  <div style="min-width: 25px;"/>
                  no modificar o manipular las marcas, logotipos, avisos comerciales, nombres comerciales y signos
                  distintivos en general de SAMII, del Sitio o de las personas vinculadas directa o indirectamente con
                  SAMII (salvo que cuente con su autorización por escrito);
                </li>
                <li class="pl-8 w-full flex flex-row">
                  d)
                  <div style="min-width: 25px;"/>
                  suprimir, eludir o modificar los contenidos y los elementos utilizados en el Sitio, así como los
                  dispositivos técnicos de protección, o cualquier mecanismo o procedimiento establecido en el Sitio.
                </li>
              </ul>
            </div>
          </div>
          <div class="mb-8">
            Queda excluida de los términos anteriores, aquella información generada a través del Sitio para uso y manejo
            del Usuario, misma que podrá ser impresa y/o copiada para los intereses que más convengan al mismo. En caso
            de que el Usuario sea una persona moral, se apegará a lo dispuesto por el artículo 148, fracción IV de la
            Ley Federal de Derechos de Autor.
          </div>
          <div class="mb-8">
            El Usuario reconoce y acepta que el uso del Sitio y de los Contenidos, es bajo su exclusiva y estricta
            responsabilidad, por lo que SAMII no será en ningún momento y bajo ninguna circunstancia, responsable por
            cualquier desperfecto o problema que se presente en el equipo de cómputo (hardware) o programas de cómputo
            (software) que utilice el Usuario para acceder o navegar en cualquier parte del Sitio.
          </div>
          <div class="mb-8">
            SAMII tiene derecho a realizar durante intervalos temporales definidos, campañas promocionales para promover
            el registro de nuevos miembros en su servicio. SAMII se reserva el derecho de modificar las condiciones de
            las compras o contrataciones de servicios, así como proceder a la exclusión de cualquiera de los mismos.
          </div>
          <div class="mb-8">
            SAMII declara que todos los Contenidos, y los elementos utilizados en el Sitio, se encuentran debidamente
            registrados y protegidos bajo las autoridades y leyes correspondientes en México. El Usuario se obliga a
            respetar todos los derechos contenidos en el Aviso de Derechos de Autor establecido en el Sitio.
          </div>
          <div class="mb-8">
            <strong>SITIOS Y CONTENIDOS AJENOS A SAMII.</strong>
            <div>
              SAMII podrá hacer uso de su derecho de publicación de cualquier material informativo y/o de sitios o
              subsitios propiedad de terceros vinculados o no a SAMII, que considere de interés para los Usuarios.
              No obstante lo anterior, SAMII se deslinda de toda responsabilidad, del acceso y/o uso que realice de la
              información ahí contenida el Usuario, y/o del uso, origen y destino de la información que se desprenda de
              ligas distintas (vínculo, hipervínculo, "link"). Toda publicación realizada dentro del Sitio no genera
              obligación ante terceros de pago por razón de promoción, publicación y/o manejo de información y/o imagen,
              a menos que se cuente con un contrato previamente firmado por las partes.
            </div>
          </div>
          <div class="mb-8">
            <strong>RESPONSABILIDAD RESPECTO A LOS CONTENIDOS</strong>
            <div>
              SAMII no asume responsabilidad alguna derivada, de manera enunciativa más no limitativa de: (i) la
              utilización que el Usuario pueda hacer de los materiales de este Sitio o de los "Sitio Web" de enlace, ya
              sean prohibidos o permitidos, en infracción de los derechos de propiedad intelectual y/o industrial de
              contenidos de la web o de terceros; (ii) los eventuales daños y perjuicios al Usuario causados por un
              funcionamiento normal o anormal de las herramientas de búsqueda, de la organización o la localización de
              los contenidos y/o acceso al Sitio y, en general, de los errores o problemas que se generen en el
              desarrollo o instrumentación de los elementos técnicos que el Sitio o programa facilite al Usuario; (iii)
              los contenidos de aquellas páginas a las que el Usuario pueda acceder desde enlaces incluidos en el Sitio,
              ya sean autorizados o no; (iv) los actos u omisiones de terceros, independientemente de la relación que
              dichos terceros pudieran tener con SAMII; (v) el acceso de menores de edad a los contenidos incluidos en
              el Sitio, así como el envío de información personal que estos pudieran realizar; ni (vi) las
              comunicaciones o diálogos en el transcurso de los debates, foros, chats y comunidades virtuales que se
              organicen a través de o entorno al Sitio de enlace, ni responderá, por tanto, de los eventuales daños y
              perjuicios que sufra el Usuario a consecuencia de dichas comunicaciones y/o diálogos
            </div>
          </div>
          <div class="mb-8">
            <strong>RESPONSABILIDAD RESPECTO A FALLAS DE LAS TECNOLOGÍAS DE INFORMACIÓN Y COMUNICACIÓN</strong>
            <div>
              SAMII no será responsable en forma alguna, cuando se produzcan:
              <ul>
                <li class="pl-8 w-full flex flex-row">
                  (i)
                  <div style="min-width: 25px;" />
                  errores o retrasos en el acceso al Sitio a la hora de introducir los datos en el formulario de
                  solicitud, la lentitud o imposibilidad de recepción por parte de los destinatarios de la confirmación
                  de la solicitud o cualquier anomalía que pueda surgir cuando tales incidencias sean debidas a
                  problemas en la red Internet, caso fortuito o fuerza mayor o cualquier otra contingencia imprevisible
                  ajena a SAMII;
                </li>
                <li class="pl-8 w-full flex flex-row">
                  (ii)
                  <div style="min-width: 25px;" />
                  fallos o incidencias que pudieran producirse en las comunicaciones, ya sea borrándose o por
                  transmisiones incompletas, de manera que no se garantiza que los servicios del Sitio estén
                  constantemente operativos;
                </li>
                <li class="pl-8 w-full flex flex-row">
                  (iii)
                  <div style="min-width: 25px;" />
                  de los errores o daños producidos al Sitio por un mal uso del servicio por parte del Usuario;
                </li>
                <li class="pl-8 w-full flex flex-row">
                  (iv)
                  <div style="min-width: 25px;" />
                  de la no operatividad o problemas en la dirección de correo electrónico facilitada por el Usuario para
                  el envío de la confirmación de la solicitud realizada.
                </li>
              </ul>
              En todos los casos expuestos, el mayor compromiso de SAMII será realizar su mejor esfuerzo para solucionar
              los problemas que estén a su alcance y ofrecer todo el apoyo necesario al Usuario para llegar a una
              solución rápida y satisfactoria de la incidencia.
            </div>
          </div>
          <div class="mb-8">
            <strong>EXCLUSIÓN DE GARANTÍAS Y DE RESPONSABILIDAD.</strong>
            <div>
              El Usuario es el único responsable del uso que pudiera dar al Sitio y su Contenido. El Usuario reconoce
              que la información de los Servicios se proporciona "como están" sin ninguna garantía expresa o implícita,
              de comerciabilidad o de aptitud para un fin determinado. SAMII no garantiza ni la precisión ni la
              integridad de la información, texto, gráficos, enlaces u otros elementos contenidos en el Sitio o
              Contenido.
            </div>
          </div>
          <div class="mb-8">
            SAMII no garantiza la operación ininterrumpida o libre de todo error del Sitio y/o su Contenido
          </div>
          <div class="mb-8">
            Puesto que toda la información referida en el Sitio y su contenido se encuentra en la nube, SAMII no
            controla y no garantiza la ausencia de virus en los Contenidos, ni la ausencia de otros elementos en los
            Contenidos que puedan producir alteraciones en el sistema informático del Usuario (software y/o hardware) o
            en los documentos electrónicos y ficheros almacenados en su sistema informático.
            <br />
            Todo material descargado u obtenido de un modo distinto al previsto en el Sitio, será bajo responsabilidad y
            riesgo único del Usuario respecto de los daños que pudiera causar en el sistema informático del dispositivo
            a través del cual realice su conexión y/o la pérdida de datos que derive de la descarga de ese material. En
            ningún caso, ni SAMII ni sus proveedores serán responsables de daño alguno derivado del uso del Sitio o
            Contenido, o de no poder usarlos, (EN PARTICULAR, SIN LIMITACIÓN ALGUNA, DE LOS DAÑOS DIRECTOS O INDIRECTOS,
            MORALES, INCIDENTALES, EXCESIVOS, REMOTOS Y/O EVENTUALES, POR LUCRO CESANTE, INTERRUPCIÓN DE LA ACTIVIDAD
            COMERCIAL O PÉRDIDA DE INFORMACIÓN Y/O INFRACCIONES DE SEGURIDAD), aun cuando se hubiera advertido a SAMII
            de dicha posibilidad.
          </div>
          <div class="mb-8">
            <strong>RETIRO DEL ACCESO AL SITIO Y/O A LOS CONTENIDOS.</strong>
            <div>
              SAMII se reserva el derecho a negar o retirar el acceso al Sitio o sus Contenidos en cualquier momento,
              sin responsabilidad alguna para SAMII y sin previo aviso, al Usuario o Usuarios que incumplan de manera
              total o parcial a los términos y condiciones aquí establecidas y/o que realicen acciones o actos
              tendientes a:
              <ul>
                <li class="pl-8 w-full flex flex-row">
                  1.
                  <div style="min-width: 25px;" />
                  "asediar" o de otra manera acosar o molestar al personal o empleados de SAMII;
                </li>
                <li class="pl-8 w-full flex flex-row">
                  2.
                  <div style="min-width: 25px;" />
                  hacerse pasar como representante o empleado de SAMII, realizando declaraciones falsas o de otro modo
                  erróneas de su vinculación con SAMII o recopilar o almacenar datos personales de otros Usuarios en
                  relación con la conducta y las actividades prohibidas;
                </li>
                <li class="pl-8 w-full flex flex-row">
                  3.
                  <div style="min-width: 25px;" />
                  falsificar encabezados o manipular identificadores del Sitio a fin de ocultar el origen de los
                  contenidos transmitidos por el Sitio;
                </li>
                <li class="pl-8 w-full flex flex-row">
                  4.
                  <div style="min-width: 25px;" />
                  cargar, publicar, enviar por correo electrónico, transmitir o proporcionar de otro modo cualquier
                  contenido respecto del cual no tenga derecho a transmitir en virtud de los términos contenidos en la
                  Ley de la Propiedad Industrial ("LPI"), Ley Federal de Derechos de Autor ("LFDA"), y la Ley Federal de
                  Protección de Datos Personales en Posesión de Particulares ("LFPDP") o de relaciones contractuales
                  protegidos por convenios de no divulgación;
                </li>
                <li class="pl-8 w-full flex flex-row">
                  5.
                  <div style="min-width: 25px;" />
                  cargar, publicar, enviar por correo electrónico, transmitir o proporcionar de otro modo materiales que
                  contengan virus informáticos o cualquier otro código informático, archivos o programas diseñados para
                  interrumpir, destruir o limitar la funcionalidad del software, hardware o de equipos de
                  telecomunicaciones conectados al Sitio;
                </li>
                <li class="pl-8 w-full flex flex-row">
                  6.
                  <div style="min-width: 25px;" />
                  hacer uso del Sitio de una manera que pudiera dañar, deshabilitar, recargar o alterar los servidores
                  de SAMII o las conexiones de redes, ignorar requisitos, procedimientos, políticas o normas de redes
                  conectadas al Sitio que pudieran interferir con el uso y goce del Sitio por parte de cualquier tercero
                  ; y/o
                </li>
                <li class="pl-8 w-full flex flex-row">
                  7.
                  <div style="min-width: 25px;" />
                  acceder de manera no autorizada a cuentas, sistemas informáticos o redes conectadas a los servidores
                  de SAMII, a través de ataques propios de piratas informáticos, el descifrado de contraseñas o
                  cualquier otro método para obtener o tratar de obtener materiales o información con cualquier medio
                  que no se ofrece intencionalmente a través del Sitio.
                </li>
              </ul>
              El Usuario acepta indemnizar y mantener a salvo a SAMII y sus funcionarios, agentes, empleados, socios y
              licenciantes frente a cualquier reclamo o demanda, así como a cubrir los honorarios razonables de abogados
              , que promueva cualquier tercero en contra de SAMII a causa del contenido que el Usuario envíe, publique,
              transmita o proporcione de un modo distinto al previsto en el Sitio. Lo anterior sin perjuicio al derecho
              de SAMII, realizar las acciones judiciales necesarias para reclamar los daños y perjuicios que dichas
              acciones por parte del Usuario pudieran causarle.
            </div>
          </div>
          <div class="mb-8">
            <strong>PROPIEDAD INTELECTUAL.</strong>
            <div>
              SAMII autoriza al Usuario a utilizar el sitio, exclusivamente en los Términos aquí expresados, sin que
              ello implique que concede al Usuario licencia o autorización alguna, o algún tipo de derecho distinto al
              uso antes mencionado respecto a la Propiedad Intelectual de SAMII,  entendiéndose como Propiedad
              Intelectual, todas las marcas registradas y/o usadas en México o en el extranjero por SAMII, así como todo
              derecho sobre invenciones (patentadas o no), diseños industriales, modelos de utilidad, información
              confidencial, nombres comerciales, avisos comerciales, reservas de derechos, nombres de dominio, así como
              todo tipo de derechos patrimoniales sobre obras y creaciones protegidas por derechos de autor y demás
              formas de propiedad industrial o intelectual reconocida o que lleguen a reconocer las leyes
              correspondientes.
              <br />
              El Usuario reconoce y acepta que SAMII es el titular de todos los derechos legítimos en el Sitio,
              incluidos las imágenes, logotipos, imagotipos, marcas y nombres comerciales de SAMII, marcas comerciales,
              marcas de servicio, logotipos, nombres de dominio y otras características distintivas de la marca
              contenidas en ellos ("Marcas Registradas de SAMII"), independientemente de que esos derechos estén
              registrados o no, y de cualquier lugar del mundo en el que puedan existir esos derechos,  y que están
              protegidos por las leyes y tratados internacionales sobre derechos de autor. Por lo anterior, el Usuario
              acepta que las Marcas Registradas de SAMII no podrán ser objeto de copia, reproducción, modificación,
              publicación, carga, envío, transmisión ni distribución en modo alguno sin el consentimiento previo por
              escrito de SAMII. Salvo indicación expresa en contrario en este documento, SAMII no concede al Usuario
              ningún derecho expreso ni implícito en virtud de patentes, derechos de autor, marcas comerciales o
              información de secretos comerciales.
            </div>
          </div>
          <div class="mb-8">
            <span style="text-decoration: underline;">Retroalimentación.</span>
            En caso de que el Cliente proporcione algún comentario a SAMII respecto de la funcionalidad y el rendimiento
            del Software (incluida la identificación de posibles errores y mejoras), en este acto, el Cliente autoriza a
            SAMII para que haga uso, sin restricción, de todos los derechos, títulos e intereses sobre los comentarios
            expresados. Lo anterior, sin que ello se considere como un derecho moral del Cliente para requerir
            participación o retribución monetaria alguna, o restricción en el uso de dichos comentarios para su
            explotación por parte de SAMII.
          </div>
          <div class="mb-8">
            <strong>DATOS DE CARÁCTER PERSONAL.</strong>
            <div>
              En cumplimiento a los términos previstos en la LFPDP, SAMII como responsable del tratamiento de sus datos
              personales, hace del conocimiento del usuario que, la información que provea en este Sitio, será tratada
              con los fines y podrá ser transmitidos para ser tratados por personas distintas a SAMII en cumplimiento de
              los términos establecidos en el Aviso de Privacidad.
              <br />
              Para utilizar o gozar de algunos de los Contenidos es necesario que el Usuario proporcione previamente a
              SAMII ciertos datos de carácter personal ("Datos Personales").
              <br />
              Al acceder al Sitio o a cualquiera de los Contenidos en que los Datos Personales son requeridos, está
              autorizando a SAMII a realizar análisis y estudios con base en ellos. El Usuario se obliga a proporcionar
              Datos Personales verdaderos y fidedignos. En caso de que el Usuario diera información falsa o confusa,
              SAMII no asume ninguna responsabilidad de los resultados que dichos actos ocasionen al Usuario, teniendo
              la facultad de negar el acceso al Sitio y sus Contenidos, sin perjuicio de que pueda requerir las
              indemnizaciones a que hubiere lugar.
            </div>
          </div>
          <div class="mb-8">
            <strong>INDICADORES DE DATOS.</strong>
            <div>
              La información que el Usuario provea en el Sitio, real o histórica, se procesa y ordena, para que genere
              indicadores de datos, mismos que SAMII podrán usar para tomar decisiones pertinentes a su negocio, bajo su
              propio análisis y responsabilidad.
              <br />
              El Usuario, en este acto autoriza el acceso a SAMII a la información proporcionada y generada en el Sitio,
              en términos del presente documento y del mencionado Aviso de Privacidad.
            </div>
          </div>
          <div class="mb-8">
            <strong>USO DE COOKIES.</strong>
            <div>
              SAMII informa al Usuario que, mediante el uso de cookies y tecnologías similares, busca garantizar la
              mejor experiencia posible en el Sitio al proporcionarle información personalizada, recordando sus
              preferencias de servicios y de mercadeo y ayudándolo a obtener la información adecuada. En caso de que el
              Usuario requiera de mayor información respecto al uso de cookies y tecnologías similares, SAMII pone a su
              disposición la Política de uso de Cookies.
            </div>
          </div>
          <div class="mb-8">
            <strong>COMPATIBILIDAD DE LOS DISPOSITIVOS ELECTRÓNICOS</strong>
            <div>
              El Usuario será responsable de obtener los dispositivos o hardware que sean compatibles con el Sitio, toda
              vez que SAMII no garantiza que éste funcione correctamente en cualquier dispositivo. De igual manera, el
              Usuario acepta no utilizar dispositivos, software o cualquier otro medio tendiente a interferir tanto en
              las actividades y/u operaciones del Sitio o en las bases de datos y/o a la información que se contenga en
              el mismo.
            </div>
          </div>
          <div class="mb-8">
            <strong>DURACIÓN Y TERMINACIÓN.</strong>
            <div>
              El Sitio y los Contenidos tienen una duración indefinida. Sin embargo, SAMII podrá dar por terminado o
              suspender temporalmente en cualquier momento y sin necesidad de previo aviso el Sitio y/o cualquiera de
              los Contenidos.
            </div>
          </div>
          <div class="mb-8">
            <strong>SOPORTE</strong>
            <div>
              SAMII ofrece al Usuario el servicio de soporte técnico y orientación básica para la utilización de las
              herramientas y las funcionalidades del Sitio, pudiendo ser por vía Chat en Línea, correo electrónico, o
              cualquier otro medio que SAMII considere conveniente y factible, en los horarios indefinidos que de igual
              forma designe para tal efecto, mediante previo aviso. Este servicio no tendrá ningún costo adicional. Así
              mismo el Usuario que hubiere solicitado el Soporte, acepta y autoriza a SAMII para tener acceso pleno a
              toda la información proporcionada en el Sitio, sin ninguna limitación. En este sentido y en beneficio del
              Usuario, SAMII se obliga a guardar plena secrecía y confidencialidad, respecto a la información a la que
              tenga acceso.
            </div>
          </div>
          <div class="mb-8">
            <strong>OTRAS DISPOSICIONES.</strong>
            <div>
              El Usuario acepta que una versión impresa de los presentes Términos, y de cualquier comunicación enviada
              y/o recibida en forma electrónica será admisible como medio probatorio en cualquier procedimiento judicial
              y/o administrativo.
            </div>
          </div>
          <div class="mb-8">
            <strong>DIVISIBILIDAD.</strong>
            <div>
              En caso que cualquier término, condición o estipulación contenida en el presente se determine ineficaz,
              ilegal o sin efecto, el mismo podrá ser excluido del cuerpo del presente y el restante continuará en vigor
              y efecto en forma tan amplia como en derecho proceda
            </div>
          </div>
          <div class="mb-8">
            <strong>DERECHOS.</strong>
            <div>
              Cualquier derecho que no se haya conferido expresamente en este documento se entiende reservado a SAMII.
            </div>
          </div>
          <div class="mb-8">
            <strong>ACTUALIZACIONES</strong>
            <div>
              SAMII podrá revisar y actualizar en cualquier momento los presentes términos y condiciones de uso del
              Sitio, manteniendo en todo momento el acceso libre a todo Usuario que desee conocerlos. SAMII se reserva
              el derecho de modificar, en cualquier momento, la presentación y configuración del Sitio, así como los
              presentes Términos y Condiciones. Por ello, SAMII recomienda al Usuario dar lectura atenta cada vez que
              acceda al Sitio. No obstante lo anterior, el Usuario siempre dispondrá de los Términos y Condiciones en el
              Sitio de forma visible y accesible en cualquier momento.
              <br />
              La aceptación de los Términos será un paso previo e indispensable a la adquisición de cualquier servicio
              disponible a través del Sitio. Algunas cláusulas de estos Términos y condiciones de uso pueden estar
              supeditadas a condiciones o avisos legales designados expresamente que se encuentren en determinados
              Sitios web.
            </div>
          </div>
          <div class="mb-8">
            <strong>LEY APLICABLE Y JURISDICCIÓN.</strong>
            <div>
              En todo lo relacionado con la interpretación y cumplimiento de lo aquí dispuesto, por el sólo hecho de
              acceder al Sitio, los Usuarios aceptan someterse a las leyes aplicables y a la jurisdicción de los
              tribunales competentes en la ciudad de Monterrey, Nuevo León, México; renunciando a cualquier otra
              jurisdicción que, por razón de sus domicilios presentes o futuros, o por cualquiera otra razón pudiese
              corresponderles.
            </div>
          </div>
          <div class="mb-8">
            <strong>ACEPTACIÓN DE LOS TÉRMINOS</strong>
            <div>
              El Usuario reconoce que el ingreso y uso del Sitio manifiesta su aceptación expresa y adhesión a los
              Términos y Condiciones a la versión publicada en el momento en que se acceda al Sitio, en términos de lo
              establecido por los artículos 1803 y 1834 Bis del Código Civil Federal, 80, 81, 89 y demás relativos y
              aplicables del Código de Comercio y la legislación aplicable para la República Mexicana. Es
              responsabilidad única y exclusiva del Usuario, leer previamente estos Términos y sus modificaciones
              correspondientes, cada vez que accede al Sitio, por lo que si en cualquier momento, el Usuario no
              estuviera de acuerdo, total o parcialmente con los presentes Términos, deberá abstenerse inmediatamente de
              acceder al Sitio y su Contenido. Por lo anterior, con la aceptación de los presentes Términos, el Usuario
              consiente expresamente sujetarse a los mismos, por lo que manifiesta haber leído el contenido de todas y
              cada una de las disposiciones y ratifica su contenido.
            </div>
          </div>
          <div class="mb-8">
            <strong>FORMA DIGITAL, ELECTRÓNICA O EN LÍNEA</strong>
            <div>
              La Partes acuerdan que la forma para perfeccionar el acuerdo de voluntades entre ellas podrá ser el de
              formato Digital, Electrónico o en Línea, en donde bastará manifestar su voluntad por medio de la
              aceptación de Términos y Condiciones, así como proporcionar los datos personales e en el propio Sitio de
              SAMII sin requerir estampar la firma en documento alguno.
            </div>
          </div>
          <div>
            Fecha de primera emisión: 10/6/2022, 6:10:14 PM.
          </div>
        </section>
      </div>
    </div>
  </public-layout>
</template>
<script>
import PublicLayout from '@/components/layouts/PublicLayout';

export default {
  name: 'TermsConditions',
  components: {
    PublicLayout,
  },
  methods: {
    redirect(url) {
      window.open(url, '_blank');
    },
  },
}
</script>
